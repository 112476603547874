<template>
  <div class="page shows-events">
    <div class="section-header-B">
      <h1>Shows &amp; Events</h1>
    </div>

    <div class="md-content">
      <div>
        <ListItem
          v-for="item in webData"
          :key="item.id"
          :title="item.title"
          :details="item.details"
          :link="item.link"
        />
      </div>
      <!-- <vue3-markdown-it :source="pageContent" class="markdown" /> -->
    </div>
    <LinkPrompter :links="promptLinks" />
  </div>
</template>

<style lang="scss" scoped>
.list-item {
  h3 {
    font-size: 1.6rem;
    margin-bottom: 0.8rem;
    font-weight: bold;
  }
  .details {
    font-family: "Torque", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial,
      sans-serif;
    font-size: 1.4rem;
    font-weight: normal;
  }
}
</style>

<script>
import ListItem from "@/components/ListItem.vue";
import LinkPrompter from "@/components/LinkPrompter.vue";
import eventsJSON from "@/page-contents/events.json";
// import content from "!raw-loader!@/page-contents/shows-and-events.md";

import getData from "@/services/dc-service.js";

export default {
  name: "ShowsEvents",
  components: {
    LinkPrompter,
    ListItem,
  },
  data: function () {
    return {
      webData: [],
      // pageContent: content,
      promptLinks: [
        {
          title: "Publications & Interviews",
          description: "Writings and interviews by DEAR CLIMATE.",
          target: "/publications-and-interviews",
        },
        {
          title: "Press",
          description: "Media coverage on DEAR CLIMATE.",
          target: "/press",
        },
      ],
    };
  },
  methods: {
    fetchData() {
      console.log("Getting data from CMS.");
      getData("events").then((response) => this.processData(response.data));
    },
    processData(input) {
      const fetchedData = input.data;
      for (let i = 0; i < fetchedData.length; i++) {
        let thisEntry = {};
        thisEntry.id = fetchedData[i].id;
        thisEntry.title = fetchedData[i].attributes.title;
        thisEntry.details = fetchedData[i].attributes.details;
        if (fetchedData[i].attributes.url) {
          thisEntry.link = fetchedData[i].attributes.url;
        } else {
          thisEntry.link = null;
        }
        this.webData.push(thisEntry);
      }
      console.log(`Data loaded: ${input.data}`);
    },
  },
  mounted() {
    // this.fetchData();
    this.processData(eventsJSON);
  },
};
</script>
